import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import styles from './ButtonWithLoader.module.scss';
import SvgIcon from 'components/SvgIcon/SvgIcon';

const ButtonWithLoader = ({
  bsStyle = 'primary',
  disabled = false,
  href = '',
  loading = false,
  onClick = null,
  style = null,
  className = '',
  text = '',
  success = false,
}) => {
  return (
    <>
      <Button
        bsStyle={success ? 'success' : bsStyle}
        disabled={disabled}
        href={href}
        onClick={onClick}
        style={style}
        className={className + ' ' + styles.button}
      >
        {success ? (
          <SvgIcon icon="tick_outline" width="27" height="27" color="white" />
        ) : (
          <>{loading ? <div className={styles.button__loading}></div> : text}</>
        )}
      </Button>
    </>
  );
};

ButtonWithLoader.propTypes = {
  bsStyle: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  success: PropTypes.bool,
};

export default ButtonWithLoader;
