import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { signInWithPassword, sendAuthCode } from '../../redux/modules/auth';
import { trackEvent } from '../../helpers/Analytics';
import styles from './SignIn.module.scss';
import ButtonWithLoader from 'components/ButtonWithLoader/ButtonWithLoader';

const SignIn = ({ location, signInWithPassword, sendAuthCode, auth }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const referer = location.query.referer || '';

  // Function to handle and format error messages
  const handleError = (error, errorType) => {
    setIsLoading(false);
    setIsSuccess(false);

    let message = '';

    // Track the error based on type
    if (errorType === 'password') {
      // For password errors
      message = error;
      trackEvent('signin-password-error', {
        error: message,
        referer,
      });
    } else if (errorType === 'passwordless') {
      // For passwordless errors
      // Extract error status from various possible formats
      const errorStatus =
        (error && error.status_code) ||
        (error && error.status) ||
        auth.errorStatus;

      const isEmailError = errorStatus === 422 || errorStatus === 404;

      if (isEmailError) {
        message =
          'Please check that your email address is correct and try again.';
      } else {
        message = 'Something went wrong. Please try again later.';
      }

      trackEvent('signin-passwordless-error', {
        error: message,
        errorCode:
          (error && error.status_code) || auth.errorStatus || 'unknown',
        referer,
      });
    }

    setErrorMessage(message);
  };

  // We don't need the refs anymore with separate useEffects

  // Track page view when component mounts
  useEffect(() => {
    trackEvent('user-lands-on-signin', { referer });
  }, []);

  // Handle password sign-in errors
  useEffect(() => {
    if (auth.passwordSignInError) {
      handleError(auth.passwordSignInError, 'password');
    }
  }, [auth.passwordSignInError]);

  // Track successful password sign-in - separate useEffect
  useEffect(() => {
    if (auth.passwordSignInResponse) {
      const data = auth.passwordSignInResponse;

      // Set success state
      setIsLoading(false);
      setIsSuccess(true);

      // Track successful password sign-in
      trackEvent('signin-password-success', {
        userType: data?.type || 'unknown',
        referer,
      });

      // Add a small delay to show success state before redirecting
      setTimeout(() => {
        // Redirect based on user type
        window.location =
          data && data.type
            ? data.type === 'seller'
              ? referer || '/jobs'
              : data.type === 'buyer'
              ? referer || '/manage/briefs'
              : referer || '/' // Fallback if type is unexpected
            : referer || '/'; // Fallback if type is not present
      }, 1000);
    }
  }, [auth.passwordSignInResponse, referer]);

  // Handle passwordless auth errors
  useEffect(() => {
    if (auth.error) {
      handleError(auth.error, 'passwordless');
    }
  }, [auth.error]);

  // Handle successful auth code creation - separate useEffect
  useEffect(() => {
    if (auth.authCodeResponse) {
      // Set success state
      setIsLoading(false);
      setIsSuccess(true);

      // Track successful auth code request
      trackEvent('signin-passwordless-auth-code-sent', { referer });

      // Add a small delay to show success state before redirecting
      setTimeout(() => {
        // Redirect to the code entry page with base64 encoded email
        const base64Email =
          typeof window !== 'undefined'
            ? btoa(email)
            : Buffer.from(email).toString('base64');

        window.location = `/auth/code?email=${base64Email}&referer=${
          referer || 'signin'
        }`;
      }, 1000);
    }
  }, [auth.authCodeResponse, referer, email]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Clear any previous errors
    setErrorMessage('');

    if (email) {
      if (showPassword) {
        // Check if password is empty when password field is shown
        if (!password) {
          setErrorMessage('Please type in your password');
          return;
        }

        // Track password sign-in attempt
        trackEvent('signin-password-attempt', { referer });

        // Set loading state
        setIsLoading(true);

        // Use Redux action for password-based authentication
        signInWithPassword(email, password);
      } else {
        // Track passwordless sign-in attempt
        trackEvent('signin-passwordless-attempt', { referer });

        // Set loading state
        setIsLoading(true);

        // Use Redux action to request an auth code
        sendAuthCode(email);
      }
    }
  };

  const togglePasswordField = (e) => {
    e.preventDefault();
    // Clear any existing error messages when switching modes
    setErrorMessage('');
    // Reset loading and success states when switching modes
    setIsLoading(false);
    setIsSuccess(false);
    // Toggle password field visibility
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.signInContainer}>
      <Helmet title="Sign In" />

      <div className={styles.formContainer}>
        <div className={styles.leftPanel}>
          <h1 className={styles.title}>SIGN IN</h1>
          <p className={styles.welcomeText}>Welcome back to Twine!</p>

          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  // Clear error message when user types
                  if (errorMessage) {
                    setErrorMessage('');
                    // No need to reset flags since we're using separate useEffects
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
                className={styles.formControl}
                placeholder="email@example.com"
              />
            </div>

            <div
              className={`${styles.passwordFormGroup} ${
                showPassword ? styles.visible : ''
              }`}
            >
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  // Clear error message when user types
                  if (errorMessage) {
                    setErrorMessage('');
                    // No need to reset flags since we're using separate useEffects
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
                className={styles.formControl}
                placeholder="Password"
              />
            </div>

            {showPassword && (
              <div className={styles.forgotPasswordContainer}>
                <a
                  href="#"
                  onClick={togglePasswordField}
                  className={styles.forgotPasswordLink}
                >
                  Forgot or lost your password? Sign in using your email
                </a>
              </div>
            )}

            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}

            <ButtonWithLoader
              onClick={handleSubmit}
              loading={isLoading}
              success={isSuccess}
              disabled={isLoading}
              bsStyle="primary"
              className={styles.signInButton}
              text={
                showPassword ? 'Sign in with password' : 'Sign in with email'
              }
            />

            <div className={styles.secureCodeMessage}>
              {showPassword ? (
                <>
                  You can also{' '}
                  <a href="#" onClick={togglePasswordField}>
                    sign in with a secure code
                  </a>{' '}
                  sent to your email.
                </>
              ) : (
                <>
                  We&apos;ll email you a secure code to sign-in, or you can{' '}
                  <a href="#" onClick={togglePasswordField}>
                    sign in using your password
                  </a>
                  .
                </>
              )}
            </div>

            <div className={styles.orDivider}>
              <span>OR</span>
            </div>

            <a
              href="/signin/google"
              className={styles.googleButton}
              onClick={() => trackEvent('signin-google-attempt', { referer })}
            >
              <svg
                className={styles.googleIcon}
                viewBox="0 0 256 262"
                width="22"
                height="22"
                fill="currentColor"
              >
                <path
                  d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  fill="#4285F4"
                ></path>
                <path
                  d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  fill="#34A853"
                ></path>
                <path
                  d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  fill="#EB4335"
                ></path>
              </svg>
              <span>Continue with Google</span>
            </a>
          </form>
        </div>

        <div className={styles.rightPanel}>
          <div className={styles.rightPanelContent}>
            <h2>New here?</h2>
            <p>Sign up to Twine and start finding work.</p>

            <a href="/signup" className={styles.signUpButton}>
              Sign up for free
            </a>

            <div className={styles.hireSection}>
              <h2>Need to hire an expert for your project?</h2>
              <p>
                It&apos;s free and you&apos;re protected by our money back
                guarantee.
              </p>

              <a href="/post-project" className={styles.postProjectButton}>
                Post a project
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  location: PropTypes.object,
  signInWithPassword: PropTypes.func.isRequired,
  sendAuthCode: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { signInWithPassword, sendAuthCode })(
  SignIn
);
